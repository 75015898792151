.HomePageComponent{
width: 100%;
max-height: 400px;
min-width: 100px;
z-index: 1;
padding: 30px 20px;
margin: 10px;
padding: 20px;
background-color: white;
color:black;
align-items: center;
}
.product__rating {
    display: flex;
  }
.homeComponetImgWrapper img{
  max-height: 200px;
  width: 100%;
  object-fit: contain;
  margin-bottom: 15px;
}
.HomePageComponent button{
    align-items: center;
    margin-left: 150px;
    background-color:yellow;
    padding: 5px;

}
.HomePageComponent button:hover{
background-color: blue;
transform: scale(1);
}
.HomePageComponent:hover{
transform: scale(1.05);
transition: 0.5s;
}